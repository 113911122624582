<template>
    <div class="nevs-content">
        <div class="help-disclaimer" v-html="$LANG.Get('texts.helpDisclaimer')"></div>
        <div class="tabs">
            <NevsButton :class="{'active' : currentTab === 1}" @click="currentTab = 1">
                {{ $LANG.Get('buttons.helpFiskal') }}
            </NevsButton>
            <NevsButton :class="{'active' : currentTab === 2}" @click="currentTab = 2">
                {{ $LANG.Get('buttons.helpEInvoices') }}
            </NevsButton>
        </div>
        <HelpFiskal class="tab-body" v-if="currentTab === 1"></HelpFiskal>
        <HelpEInvoices class="tab-body" v-if="currentTab === 2"></HelpEInvoices>
    </div>
</template>

<script>
import NevsButton from "@/components/nevs/NevsButton.vue";
import HelpFiskal from "@/components/general/HelpFiskal.vue";
import HelpEInvoices from "@/components/general/HelpEInvoices.vue";

export default {
    name: "ModuleHelp",
    components: {HelpEInvoices, HelpFiskal, NevsButton},
    data() {
        return {
            currentTab: 1
        }
    },
    watch: {},
    mounted() {
        this.$store.commit('selectMenu', 'help');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.help'),
                link: null
            }
        ]);

    }
}
</script>

<style scoped>
    .help-disclaimer {
        padding: 10px;
        font-size: 16px;
    }

    .tabs {
        margin-bottom: 10px;
        border: 2px #b7bcc0 solid;
        border-bottom: none;
        padding: 5px;
        background: #d7e3ea;
        border-radius: 5px 5px 0 0;
    }

    .tabs .nevs-button {
        border-radius: 0;
        margin-right: 10px;
        background: none;
        color: #5e6278;
        padding-bottom: 2px;
    }

    .tabs .nevs-button.active {
        border-bottom: 2px dotted #5e6278;
    }

    .tab-body {
        padding: 10px;
        margin-top: -10px;
        border: 2px #b7bcc0 solid;
        border-radius: 0 0 5px 5px;
    }
</style>